
.flagimg img{
    height: 38px;
    width: 38px;
    margin-left: 7px;
    border-radius: 50%;
  }

  .topbar{
    height: 4.375rem;
    /* background-color: darkblue; */
    margin-left: -233px;
    margin-right: -233px;
    width: 1583px;
  }