div h2 {
    font-family: -webkit-body;
    margin-left: 425px;
    font-weight: bolder;
    margin-bottom: 20px;
    font-size: 50px;
}

thead tr th {
    text-align: center;
}

tbody tr td {
    text-align: center;
}

Button.reject {
    margin-right: 179px;
    margin-left: 110px;
}

div.spinneer img {
    margin-left: -241px;
    margin-top: -120px;
}