.paper-login {
    padding: 20px;
    height: 70vh;
    width: 280px;
    margin: 20px auto;
}

.headlogo {
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-style: normal;
}

.headlogo img {
    /* height: 150px; */
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 5%;
    margin-bottom: 3%;
}

Button.loginbutton{
    background-color: #3D405B!important;
}

.under {
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.forgot-hover:hover {
    color: blueviolet;
}

.forgot-hover {
    color: black;
}

.password {
    text-decoration: none;
}

.login {
    padding: 60px;
    /* height: 120vh; */
    width: 700px;
    margin: 20px auto;
    margin-top: 0px;
}

.imgD {
    position: relative;
}

.img-h1 {
    position: absolute;
    top: 0px;
    z-index: 1;
}

.img-signup {
    height: 100vh;
    width: 430px;
}

.img-login {
    /* height: 700px; */
    width: 400px;
    margin-top: 140px;
    margin-left: 250px;
}

.login-h1 {
    color: #fff;
}

.login-field {
    width: 70%;
    border-radius: 10px;
}

.remember {
    color: #fff;
}

.grid-center {
    margin-top: 120px;
    /* margin-left: 350px; */
}

.para-signup {
    color: black;
    z-index: 1;
    margin-top: 110px;
}

.login-w {
    width: 50%;
}

.login-fieldd {
    width: 90%;
}

.img-loginn {
    height: 160vh;
    width: 100%;
}

.grid-center {
    margin-top: 140px;
}

.product-page {
    margin-top: 100px;
}

.paper-size {
    position: absolute;
    height: 370px;
    margin-top: 200px;
    width: 400px;
    margin-left: -300px;
    padding: 20px;
}

.paper-size-signup {
    position: absolute;
    height: 950px;
    margin-top: 200px;
    width: 400px;
    margin-left: -300px;
    padding: 20px;
}

.post-2 {
    z-index: 1;
    margin-top: -200px;
    margin-left: -350px;
}

.loginn {
    padding: 60px;
    height: 60vh;
    width: 700px;
    margin: 20px auto;
    margin-top: 0px;
}
.text-center{
    margin-top: -80px;
}
button:not(:disabled){
    /* background-color: #3D405B; */
    color: white;
    font-weight: bolder;
}

div.spinners img{
    height: 125px;
    margin-right: 766px;
}